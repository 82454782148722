/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {faPhoneSquareAlt, faBrowser, faExternalLinkAlt} from "@fortawesome/pro-solid-svg-icons";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong",
    a: "a",
    h2: "h2"
  }, _provideComponents(), props.components), {FontAwesomeIcon} = _components;
  if (!FontAwesomeIcon) _missingMdxReference("FontAwesomeIcon", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Burton Play"), "\n", React.createElement("div", {
    className: "w-full md:w-2/5 md:pl-6 mb-4 md:mb-0 text-right p-4 z-40 md:ml-6 bg-gray-700 rounded-r-lg md:rounded-r-none rounded-l-lg text-white inverse float-right inline-block inset-0"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Contact details:"), " ", React.createElement("br"), "\nBurton Play ", React.createElement("br"), "\nChildcare Unit ", React.createElement("br"), "\nc/o Burton Primary School ", React.createElement("br"), "\nBurton Upon Stather ", React.createElement("br"), "\nNorth Lincolnshire ", React.createElement("br"), "\nDN15 9HB"), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "tel:+441724721719"
  }, React.createElement(FontAwesomeIcon, {
    icon: faPhoneSquareAlt,
    size: "sm"
  }), " : 01724 721719")), React.createElement("a", {
    href: "http://www.burtonplay.co.uk",
    target: "_blank",
    rel: "noopener"
  }, React.createElement(FontAwesomeIcon, {
    icon: faBrowser,
    size: "sm"
  }), " : www.burtonplay.co.uk ", React.createElement(FontAwesomeIcon, {
    icon: faExternalLinkAlt,
    size: "xs"
  })), React.createElement("br"), React.createElement("br"), React.createElement(_components.p, null, "Manager: Ruth Klos ", React.createElement("br"), "\nHon. Chairperson: Scott Belton ", React.createElement("br"), "\nHon. Treasurer: Anthea Ogg"), React.createElement(_components.p, null, "Registered Charity No. 1033553")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Burton Play is a provider of quality childcare within North Lincolnshire")), "\n", React.createElement(_components.p, null, "We are Ofsted registered and a member of the PreSchool Learning Alliance."), "\n", React.createElement(_components.p, null, "Our experienced, qualified staff, offer a warm and caring atmosphere for your child, giving them opportunity to explore and learn."), "\n", React.createElement(_components.p, null, "We are delighted to work in partnership with ", React.createElement("span", {
    className: "font-black"
  }, "Burton upon Stather"), "r Primary School, to be able to provide Breakfast & After School care for families of children attending the School."), "\n", React.createElement(_components.p, null, "We are a registered charity, managed by voluntary trustees who have a passion for childcare. The Trustees aim is to continue to provide the highest quality childcare for local families."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Burton Bears"), " is the name used for the Pre-school side of Burton Play. We offer term time care for children aged 2-5 years. Children are welcome to join us for a morning session, afternoon session or stay all day. We are open for Bears children Monday to Friday, 9.00am to 3.15pm. Bears children are very welcome to extend their hours by attending KidsClub also."), "\n", React.createElement(_components.h2, null, "What is KidsClub?"), "\n", React.createElement(_components.p, null, "We offer a term time breakfast & after school club for children aged 2 – 14 years. We also offer a holiday club for same age group."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
